import React from "react";
import styled from 'styled-components'
import { PageTheme } from "./Theme";
import IconUnstyled from './Icons'

const DeleteIcon = styled(IconUnstyled)`
  height: 16px;
  opacity: 0.8;
`

const StyledDeleteButton = styled.button`
    width:24px;
    box-sizing: border-box;
    border: 0;
    outline: 0;
    z-index: 1;
    padding: 2px 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${props => props.contrast ? 'rgba(0,0,0,0.5)' : 'transparent'};
    border-radius:  ${ PageTheme.properties.ButtonBorderRadius };
    fill: #FFF;

    &:hover{
        background-color: ${PageTheme.colors.ButtonBackground };
        fill: ${ PageTheme.colors.destroy };
    }
`

export default function DeleteButton({onClick,contrast}){
    return <StyledDeleteButton onClick={onClick} contrast={contrast}><DeleteIcon id="Trash" /></StyledDeleteButton>
}