import create from 'zustand'

const usePopupStore = create((set, get) => ({
    popupComponent: null,
    
    setPopupComponent: async (popupComponent) => {
        set({'popupComponent': popupComponent});
    },
}))

export default usePopupStore