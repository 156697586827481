import React from "react";
import {Heading1, BulletPoint, Paragraph, Ol} from "./Theme"

export default function RulesText({theme='dark'}) {

  return (
    <div>
        <Heading1 text="Rules" />
          <BulletPoint icon="Lock" topic="The tracks remain your property" theme={theme}>
            <Paragraph>
              Track.gallery is only the place where the tracks are made available. Track.gallery only provides the interface for commenting and getting in touch.
            </Paragraph>
            <Paragraph>
              Your track is currently only accessible via the secret track URL.
            </Paragraph>
          </BulletPoint>
          <BulletPoint icon="ShieldOff" topic="Track security" theme={theme}>
            <Paragraph>
              Once someone clicks on your link, we have only one way to protect your track: We don't allow anyone to save your tracks. Unfortunately, current web technologies do not offer protection against downloading, copying or recording your work. Please be aware that anyone with the proper know-how can obtain copies of your audio files. 
            </Paragraph>
            <Paragraph>
              We would like to emphasise this once again:
              <Ol>
                <li>Nobody has the right to use your tracks without your permission. We forbid this.</li>
                <li>Every modern web browser downloads the track into the cache of its users. So a download takes place in any case.</li>
              </Ol>
            </Paragraph>
          </BulletPoint>
          <BulletPoint icon="AlertTriangle" topic="Obtain permission" theme={theme}>
            <Paragraph> 
              You may only upload tracks that you own. For example, if you use audio samples, you need the permission of the author to upload them here.
            </Paragraph>
            <Paragraph>
              Since this is a website operated in Germany, you may only upload songs for which you have all rights according to German law.
            </Paragraph>
          </BulletPoint>
          <BulletPoint icon="ColorPalette" topic="Art &ne; art" theme={theme}>
            <Paragraph>
              Your track is a work of art, but we reserve the right to remove it from our site if we consider it immoral, unethical or even illegal.
            </Paragraph>
          </BulletPoint>
          <BulletPoint icon="Archive" topic="We cannot be your archive :(" theme={theme}>
            <Paragraph>
              We cannot guarantee that we will always keep your track. Please keep your source files. We are not a cloud storage and do not yet know how much space we might need later.
            </Paragraph>
          </BulletPoint>
    </div>
    );
}