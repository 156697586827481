import React, { useEffect, useCallback, useRef, useMemo } from "react";
import useRaf from "@rooks/use-raf";
import usePlayerStore from "./store/player/usePlayer";
import {PageTheme} from "./Theme"
import styled from 'styled-components';

const Canvas = styled.canvas`
`

export default function CommentMarkers({sortedComments,style,maxRows=Infinity}){
    const canvas = useRef();
    const dirty = useRef();
    const mouse = useRef({x: -1, y: -1});
    const currentTime = useRef();
    const activeCommentIndex= useRef();
    const position = useRef();
    const {runtime,setActiveCommentIndex,setCursorPosition,setRequestedScrollToComment} = usePlayerStore();

    const hitarea = 4;
    const dy = 8 * window.devicePixelRatio;
    const h = 7 * window.devicePixelRatio;
    const renderCanvas = useCallback(() => {
      dirty.current = false;
  
      const ctx = canvas.current.getContext('2d');
      canvas.current.width = canvas.current.offsetWidth * window.devicePixelRatio;
      canvas.current.height = Math.min(maxRows, sortedComments.length)*dy;

      let hoverIndex = -1;
      
      for(let i = 0; i < sortedComments.length; i++){
        const y = (i%maxRows)*dy / window.devicePixelRatio;
        const comment = sortedComments[i];
        const hover = mouse.current.x >= 0 && comment.startTime * canvas.current.offsetWidth - hitarea <= mouse.current.x && mouse.current.x <= comment.endTime * canvas.current.offsetWidth + hitarea
        && y - hitarea <= mouse.current.y && mouse.current.y <= y+dy+hitarea;

        if(hover && hoverIndex === -1){
          hoverIndex = i;
        } else if(hover && hoverIndex >= 0){
          const hoverWithoutHitarea = mouse.current.x >= 0 && comment.startTime * canvas.current.offsetWidth <= mouse.current.x && mouse.current.x <= comment.endTime * canvas.current.offsetWidth
          && y <= mouse.current.y && mouse.current.y <= y+dy;
          if(hoverWithoutHitarea){
            hoverIndex = i;
          }
        }
      }

      if(hoverIndex !== -1){
        if(runtime.activeCommentIndex !== hoverIndex){
          setActiveCommentIndex(hoverIndex);
        }
      } else {
        if(mouse.current.x !== -1){
          setActiveCommentIndex(-1)
        }
      }

      const currentTime = runtime.currentTime/runtime.duration

      for(let i = 0; i < sortedComments.length; i++){
        const comment = sortedComments[i];
        const x = comment.startTime * canvas.current.width;
        const w = (comment.endTime - comment.startTime) * canvas.current.width + 1;
        const y = (i%maxRows)*dy;

        const isPlaying = comment.startTime <= currentTime && currentTime <= comment.endTime

        ctx.fillStyle = isPlaying ? PageTheme.colors.audio : "#FFF"
        ctx.globalAlpha = i === runtime.activeCommentIndex ? 1 : 0.5;
        ctx.fillRect(x, y, w, h);
      }

      if(position.current >= 0){
        ctx.globalAlpha = 1;
        ctx.strokeStyle = PageTheme.colors.audio;
        ctx.beginPath();
        ctx.moveTo(position.current * canvas.current.width, 0);
        ctx.lineTo(position.current * canvas.current.width, canvas.current.height);
        ctx.stroke();
      }

      canvas.current.style.cursor = runtime.activeCommentIndex >= 0 ? 'pointer' : 'default'
    }, [sortedComments, maxRows])
  
    const setMouseCoords = useCallback((event) => {
      const canvasRect = event.target.getBoundingClientRect();

      const x = event.touches ? event.touches[0].clientX : event.clientX;
      const y = event.touches ? event.touches[0].clientY : event.clientY;

      mouse.current.x = (x - canvasRect.left);
      mouse.current.y = (y - canvasRect.top);
      setCursorPosition((x - canvasRect.left)/canvasRect.width);

      dirty.current = true;
    }, []);
    const resetMouseCoords = useCallback(() => {
      mouse.current.x = -1;
      mouse.current.y = -1;
      setCursorPosition(-1);
     setActiveCommentIndex(-1)
      dirty.current = true;
    }, []);

    useEffect(() => {
      dirty.current = true;
    }, [sortedComments, maxRows])
  
    useRaf(() => {
      if(!canvas.current) return;

      if(runtime.currentTime !== currentTime.current){
        currentTime.current = runtime.currentTime;
        dirty.current = true;
      }

      if(runtime.activeCommentIndex !== activeCommentIndex.current){
        activeCommentIndex.current = runtime.activeCommentIndex;
        dirty.current = true;
      }

      if(runtime.cursorPosition !== position.current){
        position.current = runtime.cursorPosition;
        dirty.current = true;
      }

      if(dirty.current){
        renderCanvas();
      }
    }, true)
  
    const handleMouseOver = useCallback((e) => {
      setMouseCoords(e)
    }, []);
    const handleMouseMove = useCallback((e) => {
        setMouseCoords(e)
    }, []);
    const handleMouseOut = useCallback((e) => {
        resetMouseCoords(e)
    }, []);
    const handleTouchStart = useCallback( (e) => {
      e.preventDefault();
      setMouseCoords(e);
    }, []);
    const handleTouchMove = useCallback( (e) => {
      e.preventDefault();
      setMouseCoords(e);
      setRequestedScrollToComment(true)
    }, []);
    const handleTouchEnd = useCallback( (e) => {
      e.preventDefault();
      setRequestedScrollToComment(true)
      resetMouseCoords(e);
    }, []);
    const handleClick = useCallback( (e) => {
      setMouseCoords(e)
      setRequestedScrollToComment(true)
    }, []);

  
    useEffect(() => {
      canvas.current.addEventListener('touchstart', handleTouchStart)
      canvas.current.addEventListener('touchmove', handleTouchMove)
      canvas.current.addEventListener('touchend', handleTouchEnd)

      canvas.current.addEventListener('mouseover', handleMouseOver)
      canvas.current.addEventListener('mouseout', handleMouseOut)
      canvas.current.addEventListener('mousemove', handleMouseMove)
      
      canvas.current.addEventListener('click', handleClick)

      return () => {
          document.removeEventListener('mousemove', handleMouseMove)

          if(!canvas.current) return;
          
          canvas.current.removeEventListener('touchstart', handleTouchStart)
          canvas.current.removeEventListener('touchmove', handleTouchMove)
          canvas.current.removeEventListener('touchend', handleTouchEnd)

          canvas.current.removeEventListener('mouseover', handleMouseOver)
          canvas.current.removeEventListener('mouseout', handleMouseOut)

          canvas.current.removeEventListener('click', handleClick)
      }
  }, [])

    return <Canvas ref={canvas} style={{...style,display:'block',height:`${Math.min(maxRows, sortedComments.length)*dy/window.devicePixelRatio}px`}} />
  }