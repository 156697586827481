import React, { useCallback, useRef } from "react";
import useRaf from "@rooks/use-raf";
import usePlayerStore from "./store/player/usePlayer";
import {useDeleteComment} from "./hooks/useAuthenticatedTrack";
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import styled, { css } from 'styled-components'
import IconUnstyled from './Icons'
import {PageTheme, mediaMax} from './Theme'
import useSessionStore from "./store/session";
import DeleteButton from "./DeleteButton";
import { Button } from "./FormControls";
import { useTrackNotifications } from "./Tracks";
import Indicator from "./Indicator";
import { useDeleteNotification, useDeleteTrackNotifications } from "./hooks/useAuthenticatedNotification";
import ItemMenu from "./ItemMenu";

const Icon = styled(IconUnstyled)`
  height: 22px;
  position: relative;
`

export const CommentContainer = styled.div`
  background-color: ${ PageTheme.colors.ButtonBackgroundTransparent };
  opacity: 0.3;
  border-radius: ${ PageTheme.properties.ButtonBorderRadius };
  margin-bottom: ${ PageTheme.properties.size2 };
  border: 1px solid transparent;
  fill:  #FFF;
  font-size: 18px;
  cursor: default;
  display: flex;
  position: relative;
`

export const CommentIcon = styled(IconUnstyled)`
  height: 24px;
  margin: 8px;
`

const PlayIcon = styled(IconUnstyled)`
  height: 24px;
  margin: 8px;
  fill: ${ PageTheme.colors.audio };
  display: none;
`

export const PlayCommentIconContainer = styled.div`
  text-align: center;
  background-color: #FFFFFF22;
  border-top-left-radius:  ${ PageTheme.properties.ButtonBorderRadius };
  border-bottom-left-radius:  ${ PageTheme.properties.ButtonBorderRadius };
  
  cursor: pointer;

  &:hover ${CommentIcon} {
    display: none;
  }

  &:hover ${PlayIcon} {
    display: initial;
  }
`

export const TextContainer = styled.div`
  width: 100%;
  flex: 100%;
  padding: 8px;
`

const Row = styled.div`
  width: 100%;
  flex: 100%;
  white-space: pre-line;
`
const User = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;

  svg {
    margin-left: -2px;
  }
`
const HalfPageScrollContainer = styled.div`
  overflow-y: auto;
  position: relative;
  display: ${props => props.visible ? 'block' : 'none'};
  flex-shrink: 1;
  height: 100%;
  
  & > * {
    margin-right: 4px;
  }
`

const AutoScrollButton = styled(Button)`
    background: #ffffff33;
    width: 48px;
    height: 48px;
    svg {
      margin: 0 auto;
      width: 24px;
    }
`
const ActionButtonContainer = styled.div`
    flex-grow: 0;
    display: ${props => props.visible ? 'flex' : 'none' };
    flex-direction: row;

    & > *:not(:first-child){
      margin-left: 8px;
    }
    margin-bottom: 16px;
`
const ActionButton = styled(Button)`
    color: ${PageTheme.colors.create};
    fill: ${PageTheme.colors.create};
    display: inline-flex;
    justify-content: flex-start;
    text-align: left;
    min-height: 32px;
    svg {
      margin-right: 4px;
      width: 16px;
      flex-shrink: 0;
    }

    
  	${mediaMax('small', css`
      max-width: 170px;
      min-height: 48px;
      svg {
        margin-right: 6px;
      }
    `)}

    ${props => props.primary ? css`
      background: ${PageTheme.colors.create};
      color: #fff;
      fill: #fff;

      &:hover{
        background: ${PageTheme.colors.create};
      }
    `:''}
`

const AutoScrollButtonContainer = styled.div`
    position: absolute;
    right: 16px;
    display: none;

    &.down {
      bottom: 0;
      display: block;
      ${AutoScrollButton}{
        svg {
          transform: scaleY(-1);
          transform-origin: 0.5;
        }  
      }
    }
    &.up {
      top: 0;
      display: block;
    }
`

const Parent = styled.div`
  .active {
    fill: ${PageTheme.colors.audio};
    opacity: 0.75;
  }
  .highlighted {
      opacity: 1;
  }
`


export default function Comments({visible,track,sortedComments,showNotifications,showAllComments}){
  const {runtime,setActiveCommentIndex,setLastScrolledToComment,duration,setRequestedScrollToComment,setCurrentTime,playing,togglePause} = usePlayerStore();
  const {user, autoScrollActive, setautoScrollActive} = useSessionStore();
  const notifications = useTrackNotifications(track)
  const deleteNotification = useDeleteNotification()
  const deleteTrackNotifications = useDeleteTrackNotifications()
  const deleteComment = useDeleteComment();
  const dirty = useRef();
  const autoScrollDirty = useRef();
  const value = useRef();
  const parent = useRef();
  const activeCommentIndex = useRef();
  const requestedScrollToComment = useRef();
  const activeBars = useRef([]);
  const activeAutoScrolls = useRef(0);
  
  const updateAutoScrollButtonPosition = useCallback(() => {
    if(parent.current.parentNode.scrollTop > runtime.lastScrolledToCommentTop)
      autoScrollButton.current.classList.add('up')
    else
      autoScrollButton.current.classList.remove('up')

    if(parent.current.parentNode.scrollTop + runtime.lastScrolledToCommentParentHeight < runtime.lastScrolledToCommentTop)
      autoScrollButton.current.classList.add('down')
    else
      autoScrollButton.current.classList.remove('down')
  }, [])
  
  const doAutoScroll = useCallback((element, forceScroll) => {
    activeAutoScrolls.current++;
    scrollIntoView(element, {
      scrollMode: forceScroll ? 'always' : 'if-needed',
      block: 'center',
      inline: 'center',
    }).then(() => {
      requestAnimationFrame(() => {
        activeAutoScrolls.current--;
      })
    });
  }, []);

  const update = useCallback(() => {
    if(!parent.current) return;
    dirty.current = false;
    const children = parent.current.children;
    let scrolled = false;
    for(let i = 0; i < sortedComments.length; i++){
      if(!children[i]) continue;
      const comment = sortedComments[i];
      const isActive = comment.startTime <= value.current && value.current <= comment.endTime;
      const wasActive = activeBars.current[i];
      activeBars.current[i] = isActive;
      if(isActive && !wasActive || runtime.requestedScrollToComment && runtime.activeCommentIndex === i){
        if(!scrolled){
          scrolled = true;
          setLastScrolledToComment(children[i], parent.current.parentNode);
          updateAutoScrollButtonPosition();
          if(autoScrollActive || runtime.requestedScrollToComment){
            setRequestedScrollToComment(false);
            doAutoScroll(children[i]);
          }
        }
      }

      const isHighlighted = runtime.activeCommentIndex === i;
      if(isHighlighted) children[i].classList.add('highlighted');
      else children[i].classList.remove('highlighted');

      if(isActive) children[i].classList.add('active');
      else children[i].classList.remove('active');
    }
  }, [sortedComments, autoScrollActive]);

  useRaf(() => {
    if(!parent.current) return;

    const newValue = runtime.currentTime / duration;
    if(!isNaN(newValue) && newValue !== value.current){
      value.current = newValue;
      dirty.current = true;
    }
    if(runtime.activeCommentIndex !== activeCommentIndex.current || runtime.requestedScrollToComment !== requestedScrollToComment.current){
      activeCommentIndex.current = runtime.activeCommentIndex;
      requestedScrollToComment.current = runtime.requestedScrollToComment;
      dirty.current = true;
    }
    if(dirty.current){
      update();
    }
    if(autoScrollDirty.current){
      updateAutoScrollButtonPosition();
      autoScrollDirty.current = false;
    }
  }, true)

  const setTime = value => {
    setCurrentTime(value * duration);
    if (!playing){
      togglePause();
    }
  }

  const onScroll = useCallback(() => {
    if(activeAutoScrolls.current === 0){
      autoScrollDirty.current = true;
      if(autoScrollActive) setautoScrollActive(false)
    }
  }, [autoScrollActive]);

  const autoScroll = useCallback(() => {
    setautoScrollActive(true)
    if(runtime.lastScrolledToComment){
      doAutoScroll(runtime.lastScrolledToComment, true)
    }
  }, []);

  const handleDelete = async (comment) => {
    if(!window.confirm('The comment will be irrecoverably lost. Are you sure you want to continue?')){
      return;
    }

    deleteComment(track.uid, comment)
  };

  const autoScrollButton=useRef();

  return  <div style={{position:'relative',height:'100%'}}><HalfPageScrollContainer visible={visible} onScroll={onScroll}>
      <ActionButtonContainer visible={showNotifications}>
        <ActionButton primary onClick={() => deleteTrackNotifications(track)}>
          <Icon id ="Bell" />
          Dismiss all notifications below
        </ActionButton>
        <ActionButton onClick={showAllComments}>Show all comments</ActionButton>
      </ActionButtonContainer>
  <Parent ref={parent}>
      {sortedComments.map((comment, i) => { 
        const foundNotification = notifications.find(notification => notification.comment.id === comment.id)
        const deleteVisible = user && (track.users_permissions_user && user.id === track.users_permissions_user.id || comment.user && user.id === comment.user.id);
          return (
            <CommentContainer key={i} onMouseOver={()=>setActiveCommentIndex(i)} onMouseOut={()=>setActiveCommentIndex(-1)}>
              <ItemMenu>
                {foundNotification && <Indicator onClick={() => deleteNotification(foundNotification)}><Icon id="Bell" color="audio" /></Indicator>}
                {deleteVisible && <DeleteButton onClick={() => handleDelete(comment)} />}
              </ItemMenu>
              <PlayCommentIconContainer onClick={()=>setTime(comment.startTime)}>
                <CommentIcon id="Comment" />
                <PlayIcon id="Play" />
              </PlayCommentIconContainer>
              <TextContainer>
                <User>
                  <Icon id="Person" />
                  { comment.user ? comment.user.username : comment.displayName }
                </User>
                <Row>
                  {comment.text}
                </Row>
              </TextContainer>
            </CommentContainer>
      )})}
  </Parent>
  </HalfPageScrollContainer>
  <AutoScrollButtonContainer ref={autoScrollButton}>
    <AutoScrollButton
      visible={!autoScrollActive}
      iconOnly
      iconID = "ArrowHeadUp"
      color = {'audio'}
      onClick = {() => autoScroll()}
    />
  </AutoScrollButtonContainer></div>
}