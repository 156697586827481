import { useEffect } from 'react';
import useSessionStore from "./store/session/index"
import usePopupStore from "./store/popup/index";
import Popup from "./Popup"
import Navigation from "./Navigation"
import { useUpdateNotification } from './hooks/useAuthenticatedNotification';

export const APP_NAME = 'track.gallery';

export function setDocumentTitle(title){
	document.title = title ? `${title} - ${APP_NAME}` : APP_NAME;
}

function App() {
	const {reconnect,user} = useSessionStore(state => state);
	const {popupComponent} = usePopupStore();
	const updateNotifications = useUpdateNotification(state => state.update);

	useEffect(async () => {
		await reconnect();
	}, [])

	useEffect(async () => {
		let timer = null;
		if(user){
			await updateNotifications();
			timer = window.setInterval(() => {
				updateNotifications();
			}, 60000);
		}

		return () => {
			if(timer !== null){
				window.clearInterval(timer);
			}
		}
	}, [user])



  return (
	<div className="App">
		<Popup visible={popupComponent}>
			{popupComponent}
		</Popup>
		<Navigation />
	</div>
  );
}

export default App;
