import React,{useEffect, useState} from "react";
import useSessionStore from "./store/session/index"

import {HalfPageContainer,Heading1,Paragraph} from "./Theme"
import { Button } from "./FormControls";

export default function ConfirmEmail(){
    const reconnect = useSessionStore(state => state.reconnect);
    const resendConfirmation = useSessionStore(state => state.resendConfirmation);

    const [state,setState] = useState({state:'initial'});

    useEffect(() => {
        const timer = setInterval(async () => {
            await reconnect({dontSetIsReconnecting:true});
        }, 10000)
        return () => clearInterval(timer);
    })

    const resend = async () => {
        setState({state:'sending'})
        const {email} = await resendConfirmation();
        setState({state:'sent', email})
    };

    return (
        <HalfPageContainer>
            <Heading1 text="Please confirm your email address " />
            <Paragraph>
                {state.state==='sent'
                    ? `A new email was just sent to ${state.email}. Please use the confirmation link of that email.`
                    : <>
                        If your confirmation email got lost, we can  
                        <Button color="ButtonBackground" onClick={resend} style={{display: 'inline'}}>send you another confirmation link.</Button>
                    </>
                }                
            </Paragraph>
        </HalfPageContainer>
    );
}
