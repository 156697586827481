import React, { useEffect } from "react";
import useSessionStore from "./store/session/index"
import Tracks from './Tracks'
import Logout from './Logout'
import {HalfPageContainer, Heading1, Heading2} from "./Theme"
import { setDocumentTitle } from "./App";
import {Submenu, SubmenuGroup, SubmenuButton} from "./FormControls"
import {useHistory} from "react-router-dom";


export default function Profile() {
  const user = useSessionStore(state => state.user);
  const history = useHistory();
	useEffect(() => setDocumentTitle(user ? user.username : ''), [user])

  return (
    <HalfPageContainer>
      <Heading1 text={ 'User: ' + user.username } iconID="CloudUpload" />
      <Submenu>
        <SubmenuGroup>
          <SubmenuButton onClick={() => history.push('/track/upload')} color="create" text="Upload Track" iconID="CloudUpload" />
        </SubmenuGroup>
        <SubmenuGroup>
          <Logout />
        </SubmenuGroup>
      </Submenu>

      <Tracks />

    </HalfPageContainer>
  );
}