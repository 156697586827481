import create from 'zustand'
import { upload, loadTrack, loadPlaylist, fetchMine, comment, deleteTrack, deleteComment, deleteImage, updatePlaylists, uploadImage, updateImages } from './api'
import { apiBaseUrl } from '../../config/environment'

function prefixUrls(prefix, obj){
    if(!obj) return;
    
    if(typeof obj === 'object'){
        if(typeof obj.url === 'string' && obj.url[0] === '/'){
            obj.url = `${prefix}${obj.url}`
        }
        Object.values(obj).forEach(prop => prefixUrls(prefix, prop))
    }
}

const useTrackStore = create((set, get) => ({
    track: null,
    playlist: null,
    activeCommentIndex: -1,
    tracks: [],
    playlists: [],

    // with jwt

    upload: async (jwt, data) => {
        const { file, title } = data;
        const { track, ok, error } = await upload(jwt, {file}, title);
        if(ok){
            prefixUrls(apiBaseUrl.slice(0, -1), track);
            return {ok, track};
        } 
        return {error};
    },

    uploadImages: async (jwt, trackUid, images) => {
        let track;
        for(const image of images){
            const { track : newTrack, ok, error } = await uploadImage(jwt, trackUid, image);
            track = newTrack;

            if(!ok){
                return {error};
            }
        }

        get().replaceTrack(track);
        
        return track;
    },

    updateImages: async (jwt, track, images) => {
        const {replaceTrack} = get();
        let orderDidChange = false;
        let captionDidChange = false;
        let index = 0;
        for(const image of images){
            if(image.id !== track.images[index].id){
                orderDidChange = true;
                break;
            }
            if(image.caption !== track.images[index].caption){
                captionDidChange = true;
                break;
            }
            index++;
        }

        if(orderDidChange || captionDidChange){
            const {track:newTrack} = await updateImages(jwt, track.uid, images);
            await replaceTrack(newTrack);
        }
    },

    fetchMine: async (jwt) => {
        const { tracks, playlists } = await fetchMine(jwt);
        prefixUrls(apiBaseUrl.slice(0, -1), tracks);
        prefixUrls(apiBaseUrl.slice(0, -1), playlists);
        set({ tracks, playlists })
        return {tracks, playlists};
    },

    comment: async (jwt, authenticated, data) => {
        const { track } = await comment(jwt, authenticated, data);
        prefixUrls(apiBaseUrl.slice(0, -1), track);
        get().replaceTrack(track);
        return track;
    },

    replaceTrack: async track => {
        prefixUrls(apiBaseUrl.slice(0, -1), track);
        const replaceCurrentTrack = t => t.uid === track.uid ? track : t;
        const {playlist,tracks} = get();
        set({
            track, 
            tracks: tracks.map(replaceCurrentTrack),
            playlist: playlist && ({...playlist, tracks: playlist.tracks.map(t => ({...t, track: replaceCurrentTrack(t.track)}))}),
         });
    },

    deleteTrack: async (jwt, data) => {
        await deleteTrack(jwt, data);
        return (await get().fetchMine(jwt)).tracks;
    },

    deleteImage: async (jwt, trackUid, image) => {
        const {track} = await deleteImage(jwt, trackUid, image);
        get().replaceTrack(track);
        return track;
    },

    deleteComment: async (jwt, trackUid, comment) => {
        const { track } = await deleteComment(jwt, trackUid, comment);
        get().replaceTrack(track);
        return track;
    },

    updatePlaylists: async (jwt, data) => {
        const { playlists, ok, error } = await updatePlaylists(jwt, data);
        if(ok){
            prefixUrls(apiBaseUrl.slice(0, -1), playlists);

            let playlist = get().playlist;
            const replacePlaylist = playlist && playlists.find(l => l.id === playlist.id);
            if(replacePlaylist && replacePlaylist.id === playlist.id) playlist = replacePlaylist;

            set({playlists,playlist})
            return {ok, playlists};
        } 
        return {error};
    },
    
    // public

    loadTrack: async (uid) => {
        const { track } = await loadTrack(uid);
        prefixUrls(apiBaseUrl.slice(0, -1), track);
        set({ track, playlist: null })
        return track;
    },

    setTrack: async (track) => {
        set({ track })
        return track;
    },

    loadPlaylist: async (uid) => {
        const { playlist } = await loadPlaylist(uid);
        prefixUrls(apiBaseUrl.slice(0, -1), playlist);
        set({ playlist })
        return playlist;
    },

    setActiveComment: async (event) => {
        set({ activeCommentIndex: Math.round(event.target.dataset.index) })
    },

    resetActiveComment: async (event) => {
        set({ activeCommentIndex: -1 })
    },

}))

export default useTrackStore