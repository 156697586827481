import create from 'zustand'
import { update, deleteNotification, deleteTrackNotifications } from './api'

const useNotificationStore = create((set, get) => ({
    notifications: [],

    update: async (jwt) => {
        const { ok, notifications } = await update(jwt);
        if(ok){
            const { notifications: prevNotifications } = get();

            if(notifications.length !== prevNotifications.length || prevNotifications.length && prevNotifications[notifications.length - 1].id !== notifications[notifications.length - 1].id){
                set(({ notifications }));
            }
        }
    },

    deleteNotification: async (jwt, notification) => {
        const { ok, notifications } = await deleteNotification(jwt, notification.id);
        if(ok){
            set(({ notifications }));
        }
    },

    deleteTrackNotifications: async (jwt, track) => {
        const { ok, notifications } = await deleteTrackNotifications(jwt, track.id);
        if(ok){
            set(({ notifications }));
        }
    },
}))

export default useNotificationStore;