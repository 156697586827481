import React from "react";
import {HalfPageContainer} from "./Theme"
import RulesText from "./RulesText"

export default function Rules() {

  return (
    <HalfPageContainer>
      <RulesText theme="light" />
    </HalfPageContainer>
    );
}