import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import useSessionStore from "./store/session/index"
import {TextField, Button, Submenu, SubmenuGroup, SubmenuButton} from "./FormControls.js"
import {HalfPageContainer, Heading1} from "./Theme"
import { Link, useHistory } from "react-router-dom";
import LoadingSpinner, { DarkeningSpinner } from "./LoadingSpinner";
import { setDocumentTitle } from "./App";

export default function Login() {
	useEffect(() => setDocumentTitle('Login'), []);

	const { register, handleSubmit, reset, errors } = useForm({ mode: "all" });
	const login = useSessionStore(state => state.login);
	const user = useSessionStore(state => state.user);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const onSubmit = async (credentials) => {
		setLoading(true);
		const loginOk = await login(credentials);
		setLoading(false);
		if(loginOk){
			reset();
		} else {
			setError(true)
		}
	};

	if(user){
		return <div>
				{user.email}
			</div>
		;
	}

  return (
	<HalfPageContainer>
		{loading&&<DarkeningSpinner>
			<LoadingSpinner />
		</DarkeningSpinner>}
		<Heading1 iconID="Person" text="Login" />
		<form onSubmit={handleSubmit(onSubmit)}>
			<TextField
				label = "Username / E-Mail"
				type = "text"
				name = "identifier"
				placeholder = "Username / E-Mail"
				register={register({ required: "Username is required" })}
				errors = {errors}
			/>

			<TextField
				label = "Password"
				type = "password"
				name = "password"
				placeholder = "< Password >"
				register={register({ required: "Password is required" })}
				errors = {errors}
			/>
			
			{error && <div>Invalid credentials</div>}
			
			<Submenu>
				<SubmenuGroup>
					<SubmenuButton
						text = 'Login'
						iconID = 'LogIn'
						type = 'submit'
						color = 'create'
					/>
				</SubmenuGroup>

				<SubmenuGroup>
					<SubmenuButton
						text = 'Sign up'
						iconID = 'Heart'
						color = 'create'
						onClick = {() => history.push('/signup')}
					/>
				</SubmenuGroup>
			</Submenu>
		</form>
	</HalfPageContainer>
  );
}