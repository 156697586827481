import React from "react";
import { ReactComponent as CloudUpload} from './icons/cloud-upload-outline.svg'
import { ReactComponent as Headphones} from './icons/headphones-outline.svg'
import { ReactComponent as LogOut} from './icons/log-out-outline.svg'
import { ReactComponent as LogIn} from './icons/log-in-outline.svg'
import { ReactComponent as File} from './icons/file-outline.svg'
import { ReactComponent as Download} from './icons/download-outline.svg'
import { ReactComponent as Lock} from './icons/lock-outline.svg'
import { ReactComponent as People} from './icons/people-outline.svg'
import { ReactComponent as AlertTriangle} from './icons/alert-triangle-outline.svg'
import { ReactComponent as Info} from './icons/info-outline.svg'
import { ReactComponent as Play} from './icons/play-circle-outline.svg'
import { ReactComponent as Pause} from './icons/pause-circle-outline.svg'
import { ReactComponent as Comment} from './icons/message-square-outline.svg'
import { ReactComponent as ChainConnected} from './icons/chain-connected.svg'
import { ReactComponent as ChainDisconnected} from './icons/chain-disconnected.svg'
import { ReactComponent as Plus} from './icons/plus-circle-outline.svg'
import { ReactComponent as Trash} from './icons/trash-2-outline.svg'
import { ReactComponent as CloseX} from './icons/close-outline.svg'
import { ReactComponent as Clock} from './icons/clock-outline.svg'
import { ReactComponent as Text} from './icons/text-outline.svg'
import { ReactComponent as Plane} from './icons/paper-plane-outline.svg'
import { ReactComponent as Person} from './icons/person-outline.svg'
import { ReactComponent as PersonBackground} from './icons/person-background.svg'
import { ReactComponent as SimpleArrowDown} from './icons/arrow-ios-downward-outline.svg'
import { ReactComponent as SimpleArrowUp} from './icons/arrow-ios-upward-outline.svg'
import { ReactComponent as ArrowHeadUp} from './icons/arrowhead-up-outline.svg'
import { ReactComponent as Heart} from './icons/heart-outline.svg'
import { ReactComponent as Eye} from './icons/eye-outline.svg'
import { ReactComponent as ColorPalette} from './icons/color-palette-outline.svg'
import { ReactComponent as Archive} from './icons/archive-outline.svg'
//import { ReactComponent as Crosshair} from './icons/crosshair.svg'
import { ReactComponent as Expand} from './icons/expand-outline.svg'
import { ReactComponent as VolumeOff} from './icons/volume-off-outline.svg'
import { ReactComponent as VolumeUp} from './icons/volume-up-outline.svg'
import { ReactComponent as VolumeDown} from './icons/volume-down-outline.svg'
import { ReactComponent as VolumeMute} from './icons/volume-mute-outline.svg'
import { ReactComponent as LoaderOutline} from './icons/loader-outline.svg'
import { ReactComponent as FolderAdd} from './icons/folder-add-outline.svg'
import { ReactComponent as Edit} from './icons/edit-outline.svg'
import { ReactComponent as Checkmark} from './icons/checkmark-outline.svg'
import { ReactComponent as Undo} from './icons/undo-outline.svg'
import { ReactComponent as ChevronLeft} from './icons/chevron-left-outline.svg'
import { ReactComponent as ChevronRight} from './icons/chevron-right-outline.svg'
import { ReactComponent as List} from './icons/list-outline.svg'
import { ReactComponent as ShieldOff} from './icons/shield-off-outline.svg'
import { ReactComponent as ExternalLink} from './icons/external-link-outline.svg'
import { ReactComponent as CheckboxChecked} from './icons/checkmark-square-2-outline.svg'
import { ReactComponent as CheckboxUnchecked} from './icons/checkboxUnchecked.svg'
import { ReactComponent as Bell} from './icons/bell-outline.svg'


const validIcons = {
    CloudUpload,
    LogOut,
    LogIn,
    Person,
    PersonBackground,
    People,
    File,
    Download,
    Text,
    Plane,
    Lock,
    Plus,
    Trash,
    CloseX,
    SimpleArrowDown,
    SimpleArrowUp,
    ArrowHeadUp,
    AlertTriangle,
    Info,
    Comment,
    ChainConnected,
    ChainDisconnected,
    Headphones,
    Play,
    Pause,
    Clock,
    Heart,
    Eye,
    ColorPalette,
    Archive,
    // Crosshair,
    Expand,
    VolumeMute,
    VolumeOff,
    VolumeUp,
    VolumeDown,
    LoaderOutline,
    FolderAdd,
    Edit,
    Checkmark,
    Undo,
    ChevronLeft,
    ChevronRight,
    List,
    ShieldOff,
    ExternalLink,
    CheckboxChecked,
    CheckboxUnchecked,
    Bell
}

export default function ReturnIcon(props) {
    var iconID = props.id;
    if (iconID in validIcons) {
        const RequestedIcon = validIcons[props.id];
        return (
            <RequestedIcon className={props.className} />
        );   
    }
    else
    {
        return ('The requested iconID: ' + iconID + ' is not defined.');
    }
}
