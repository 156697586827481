import React, { useEffect } from "react";
import styled from 'styled-components';
import { setDocumentTitle } from "./App";
import {Track} from "./Tracks";
import { Heading1 } from "./Theme.js";


const PlaylistContainer = styled.div`
  position: relative;
  border-radius: 8px;
  margin: 32px 0;
`

const PlaylistContent = styled.div`
  &:not(:first-child) {
    margin-top: 16px;
  }
`
const EmptyPlaylist = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: calc(100% - 42px);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  color: #ffffff44;
`
export default function Playlist({playlist}){
  useEffect(() => {
    if(playlist){
      setDocumentTitle(playlist.name);
    }
  }, [playlist])


  return (
    <PlaylistContainer>
      {playlist.name && <Heading1 text={(playlist.name)} />}
      <PlaylistContent>
        {playlist.tracks.map((track,i) => (
          <div key={i}>
            {track.track && track.track.file && <Track playlist={playlist} isLink={true} track={track} />}
          </div>
        ))}
        </PlaylistContent>
      {playlist.tracks.length === 0 && <EmptyPlaylist>No tracks in this playlist</EmptyPlaylist>}
    </PlaylistContainer>
  );
}