import React from "react";
import styled from 'styled-components'

export const SmallText = styled.div`
  font-size: 12px;
`

export default function RecaptchaDisclaimer(){
    return <SmallText>This site is protected by reCAPTCHA and the Google{' '}
    <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and{' '}
    <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</SmallText>
}