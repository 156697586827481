import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import useTrackStore from "./store/track/index";
import usePlayerStore from "./store/player/usePlayer";
import LoadingSpinner from "./LoadingSpinner";
import Track from "./Track";
import Playlist from "./Playlist";


export default function PlaylistLoader({match: {params: {uid,id}}}){
  const {loadPlaylist,playlist,setTrack,track} = useTrackStore();
  const {setFile} = usePlayerStore();
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    if(!playlist || playlist.uid !== uid){
      setLoading(true);
      await loadPlaylist(uid);
      setLoading(false);
    }
  }, [uid]);

  useEffect(async () => {
    const foundTrack = playlist && playlist.tracks.find(track => track.id == id)
    if(foundTrack && (track ? foundTrack.track.id !== track.uid : true)){
      setTrack(foundTrack.track);
      setFile(foundTrack.track.file, `/playlist/${playlist.uid}/${foundTrack.id}`);
    } else {
      setTrack(null);
    }
  }, [playlist, id, track])


  return loading ? <LoadingSpinner /> : track ? <Track /> : playlist ? 
    <>
      <Playlist playlist={playlist} />
    </>
    : 'not found'
}