import React from "react";
import useSessionStore from "./store/session/index"
import {SubmenuButton} from "./FormControls"

export default function Logout() {
	const logout = useSessionStore(state => state.logout);

  return (
      <SubmenuButton text="Logout" color="destroy" iconID="LogOut" onClick={logout} />
  );
}