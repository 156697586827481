import React, { useCallback, useEffect } from "react";
import styled, {css} from "styled-components";
import {PageTheme, mediaMax} from "./Theme"
import usePopupStore from "./store/popup/index";
import IconUnstyled from "./Icons"


const PopupContainer = styled.div`
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0,0,0,0.5);

    display: ${ props => props.visible ? '' : 'none' };
`

const PopupDialog = styled.div`
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  z-index: 10;
  background-color: #FFF;
  color: #000;
  overflow-y: auto;

  border-radius: ${ PageTheme.properties.size2 };

  ${mediaMax('small', css`
    top: 10%;
    left: 2%;
    width: 96%;
    height: 88%;
  `)}
`

const CloseXContainer = styled.div`
  cursor: pointer;
  position: fixed;
  top: calc(10% + ${ PageTheme.properties.size3 });
  right: calc(10% + ${ PageTheme.properties.size3 });
  fill: ${ PageTheme.colors.destroy };

  ${mediaMax('small', css`
    top: 10%;
    right: 2%;
    border-top-right-radius: ${ PageTheme.properties.size2 };
    border-top-left-radius: ${ PageTheme.properties.size2 };
    background-color: #FFF;
  `)}
`

const Icon = styled(IconUnstyled)`
  height: ${ PageTheme.properties.size6 };
  fill: inherit;

  ${mediaMax('small', css`
    height: ${ PageTheme.properties.size5 };
  `)}
`

const ComponentContainer = styled.div`
  margin: ${ PageTheme.properties.size6 };
  ${mediaMax('small', css`
    margin: ${ PageTheme.properties.size4 };
  `)}
`

export default function Popup({children, visible}) {
  const {setPopupComponent} = usePopupStore();
  
  const handleKey = useCallback((event) => {
    if(!visible) return;
    const isInput = ['TEXTAREA', 'INPUT', 'BUTTON'].includes(document.activeElement.tagName);
    if(!isInput && event.key === 'Escape') {
      setPopupComponent(null);
      event.stopPropagation();
    }
  }, [visible]);

  useEffect(() => {
    document.addEventListener('keydown', handleKey);
    return () => {
        document.removeEventListener('keydown', handleKey);
    }
  }, [visible]);
  
  return (
    <PopupContainer visible={visible} onClick={ () => setPopupComponent(null)}>
        <PopupDialog onClick={ (event) => event.stopPropagation() } className="light">
            <CloseXContainer onClick={() => setPopupComponent(null)}>
              <Icon id="CloseX" color="destroy" />
            </CloseXContainer>
            <ComponentContainer>
              {children}
            </ComponentContainer>
        </PopupDialog>
    </PopupContainer>
  );
}