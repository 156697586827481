import React, { useEffect, useCallback, useState, useMemo } from "react";
import useTrackStore from "./store/track/index"
import { useFetchMyTracks, useDeleteTrack, useUpdatePlaylists } from "./hooks/useAuthenticatedTrack";
import styled from 'styled-components'
import { Link } from "react-router-dom";
import {Button, TextField, Icon} from './FormControls' 
import {PageTheme} from './Theme'
import DeleteButton from "./DeleteButton";
import { ReactSortable } from "react-sortablejs";
import {Heading2} from "./Theme"
import LoadingSpinner, { DarkeningSpinner } from "./LoadingSpinner";
import Waveform from "./Waveform";
import useNotificationStore from "./store/notification";
import Indicator from "./Indicator";
import ItemMenu from "./ItemMenu";


const ButtonStyled = styled(Button)`
  height: 100% !important;
  float: left;
  width: 100%;
  margin-top: 0px !important;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FFFFFF12;
  &:hover{
    background-color: #FFFFFF1C;
  }
`

const StyledTrack = styled.div`
  height: ${PageTheme.properties.size6};
  margin-top: ${ PageTheme.properties.size2 };
  margin-bottom: ${ PageTheme.properties.size2 };
  display: block;
  position: relative;
`

const PlaylistContainer = styled.div`
  position: relative;
  background: #ffffff09;
  border-radius: 8px;
  margin: 16px 0;
  ${props => props.editActive ? `cursor: move;` : ``}
  
`

const Playlist = styled.div`
  border: 1px dashed #ffffff99;
  min-height: 48px;
  padding: 4px 8px;
  border-radius: 8px;
  margin-top: 8px;
`
const TextFieldContainer = styled.div`
  padding: 8px 8px;
  ${props => props.editActive ? `padding-bottom: 0;` : `font-weight: bold;`}
  input {
    font-size: 16px;
    line-height: 24px;
  }
`
const EmptyPlaylist = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: calc(100% - 42px);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  color: #ffffff44;
`
const EditButton = styled(Button)`
  height: 40px;
  width: 40px;
  float: right;
  margin-left: 8px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
`

const SaveCancelEditButton = styled(Button)`
  height: 40px;
  float: right;
  margin-left: 8px;
`
const SortArea = styled.div`
  .sortable-chosen {
    background: #ffffff22;
  }
`

const EmptyContainer = styled.div``

export function useTrackNotifications(track){
  const notifications = useNotificationStore(state => state.notifications);
  return useMemo(() => {
    if(!track) return [];
    return notifications.filter(notification => {
      switch(notification.type){
        case 'track.comment':
          return notification.comment.track.id === track.id
      }
      return false;
    });
  }, [notifications, track])
}


const StyledTrackCommentIndicator = styled.div`
  pointer-events: none;
`

function TrackCommentIndicator({track}){
  const notifications = useTrackNotifications(track)

  const num = notifications.length;

  return <StyledTrackCommentIndicator>
    {num && <Indicator>+{num}<Icon id="Comment" color="audio" /></Indicator> || null}
  </StyledTrackCommentIndicator>
}

export function Track({track,playlist,isLink=true,deleteHandler}){
  if(!track) return null;

  const LinkTag = isLink ? Link : EmptyContainer;

  return <LinkTag to={playlist ? `/playlist/${playlist.uid}/${track.id}` : `/track/${track.track.uid}`}>
    <StyledTrack>
      {track.track.file && track.track.file.formats.svgWaveform && <Waveform track={track.track} style={{cursor: 'move', height: '100%',width:'100%'}} />}
      <span style={{pointerEvents: isLink?'inherit':'none'}}><ButtonStyled cursor={isLink ? undefined : 'move'} iconID="Headphones" text={track.track.title} color="audio" /></span>
      <ItemMenu>
        {isLink && <TrackCommentIndicator track={track.track} />}
        {deleteHandler && <DeleteButton onClick={deleteHandler} />}
      </ItemMenu>
    </StyledTrack>
  </LinkTag>
}

export default function Tracks() {
	const tracks = useTrackStore(state => state.tracks);
  const fetchMine = useFetchMyTracks();
  const storePlaylists = useTrackStore(state => state.playlists);
  const updatePlaylists = useUpdatePlaylists()
  const deleteTrack = useDeleteTrack();

  const [playlists, setPlaylists] = useState([]);
  const [editActive,setEditActive] = useState(false);
  const [loading,setLoading] = useState(false);

  useEffect(async () => await fetchMine(), []);
  
  const trackList = useMemo(() => {
    return tracks.map(track => ({track}));
  }, [tracks]);

  const refreshPlaylists = useCallback(() => {
    setPlaylists(storePlaylists.map((playlist, i) => ({...playlist, tracks: playlist.tracks.map((track, j) => ({...track, key:j})), key: i})))
  }, [storePlaylists])

  useEffect(() => {
    refreshPlaylists();
  }, [storePlaylists])

  const setPlaylist = (listIndex, newList) => {
    const newPlaylists = playlists.map((playlist, index) => listIndex === index ? {...playlist, tracks: newList.map((track, i) => ({...track, key:i}))} : playlist );
    setPlaylists(newPlaylists);
  }

  const deletePlaylist = useCallback(async (index) => {
    const newPlaylists = [...playlists]
    newPlaylists.splice(index, 1);
    setPlaylists(newPlaylists)
  }, [playlists]);


  const setPlaylistName = useCallback((index, name) => {
    setPlaylists(playlists.map((playlist, i) => index === i ? ({...playlist, name}) : playlist));
  }, [playlists]);

  const cancel = useCallback(() => {
    refreshPlaylists();
    setEditActive(false)
  }, [storePlaylists]);

  const save = useCallback(async () => {
    setEditActive(false)
    setLoading(true);
    await updatePlaylists(playlists);
    setLoading(false);
  }, [playlists]);

  const onDeletePlaylistTrack = useCallback((event, playlistIndex, trackIndex) => {
    event.preventDefault();

    const tracks = [...playlists[playlistIndex].tracks];
    tracks.splice(trackIndex, 1)
    setPlaylist(playlistIndex, tracks)
  }, [playlists])


  const Sortable = editActive ? ReactSortable : EmptyContainer;

  const onDeleteTrack = useCallback(async (event, track) => {
    event.preventDefault();

    if(!window.confirm('Track and comments will be irrecoverably lost. Are you sure you want to continue?')){
      return;
    }

    await deleteTrack(track)
  }, [])

  return (
    <div>
      {editActive && <SaveCancelEditButton iconID="Checkmark" text="Save" color="create" onClick={save} isActive={true} />}
      {editActive && <SaveCancelEditButton iconID="Undo" text="Cancel" color="destroy" onClick={cancel} isActive={true} />}
      {!editActive && <EditButton iconID="Edit" iconOnly color="create" onClick={() => setEditActive(true)} isActive={false} >{loading && <><DarkeningSpinner><LoadingSpinner /></DarkeningSpinner><Icon id ="Edit"/></>}</EditButton>}
      <Heading2 text="Playlists" iconID="Headphones" />
      <SortArea>
        <Sortable group={{ name:'playlists', pull: false }} list={playlists} setList={setPlaylists} style={{minHeight:'32px'}}>
          {playlists.map((playlist, playlistIndex) => (
            <PlaylistContainer key={playlist.key} editActive={editActive}>
              <ItemMenu>
                {editActive && <DeleteButton onClick={() => deletePlaylist(playlistIndex)} />}
              </ItemMenu>
              <TextFieldContainer editActive={editActive}>
                {editActive && <TextField hideLabel placeholder="Enter your playlist's title" defaultValue={playlist.name} onChange={(evt) => setPlaylistName(playlistIndex, evt.target.value)} />}
                {!editActive && <Link to={`/playlist/${playlist.uid}`}>{(playlist.name || playlist.uid)}</Link>}
              </TextFieldContainer>
              <Sortable group={{name:'tracks'}} list={playlist.tracks} setList={(v) => setPlaylist(playlistIndex, v)} tag={Playlist}>
                {playlist.tracks.map((track, trackIndex) => (
                  <div key={track.key}>
                    {track.track && track.track.file && <Track isLink={!editActive} track={track} playlist={playlist} deleteHandler={editActive && ((event) => onDeletePlaylistTrack(event, playlistIndex, trackIndex))} />}
                  </div>
                ))}
              </Sortable>
              {editActive && playlist.tracks.length === 0 && <EmptyPlaylist>Drag the tracks below and drop them here</EmptyPlaylist>}
            </PlaylistContainer>
          ))}
        </Sortable>
        {playlists.length === 0 && 'You don\'t have any lists yet.'}
        {editActive ? <Button iconID="FolderAdd" text="Create new playlist" color="create" onClick={() => setPlaylists([...playlists, {key: Math.random(),tracks:[]}])} />:<span/>}
        
        <Heading2 text="Tracks" iconID="Headphones" />
        <Sortable selectedClass="selected" group={{name:'tracks', pull:'clone'}} sort={false} list={trackList} setList={() => {}}>
          {trackList.map((item, i) => (
            <div key={i}>
              {item.track && item.track.file && <Track track={item} isLink={!editActive} deleteHandler={(event) => onDeleteTrack(event, item.track)} />}
            </div>
          ))}
        </Sortable>
      </SortArea>
    </div>
  );
}