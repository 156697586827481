import React, { useEffect, useCallback, useRef } from "react";
import useRaf from "@rooks/use-raf";
import usePlayerStore from "./store/player/usePlayer";


export default function Waveform({track,style}){
    const {runtime,file} = usePlayerStore()

    const canvas = useRef();
    const dirty = useRef();
    const image = useRef();
    const preparing = useRef();
    const isActive = useRef(false);
    const progress = useRef();

    const renderCanvas = useCallback(() => {
      dirty.current = false;

      canvas.current.width = canvas.current.offsetWidth * window.devicePixelRatio;
      canvas.current.height = canvas.current.offsetHeight * window.devicePixelRatio;
      const ctx = canvas.current.getContext('2d');

      if(isActive.current){

        const sWidth = image.current.width * progress.current;
        const dWidth = canvas.current.width * progress.current;
        ctx.globalAlpha = 0.3;
        ctx.drawImage(image.current, 0, 0, sWidth, image.current.height, 0, 0, dWidth, canvas.current.height);

        ctx.globalAlpha = 0.1;
        ctx.drawImage(image.current, sWidth, 0, image.current.width, image.current.height, dWidth, 0, canvas.current.width, canvas.current.height);
      } else {
        ctx.globalAlpha = 0.15;
        ctx.drawImage(image.current, 0, 0, image.current.width, image.current.height, 0, 0, canvas.current.width, canvas.current.height);
      }
    }, [])
  

    const prepareSvg = useCallback(() => {
      if(preparing.current) return false;

      preparing.current = true;
      const svgCanvas = document.createElement('canvas')
      const svgCtx = svgCanvas.getContext("2d");
      svgCanvas.width = canvas.current.offsetWidth;
      svgCanvas.height = canvas.current.offsetHeight;
  
      const svgImage = new Image();
      svgImage.src = track.file.formats.svgWaveform.url;
      svgImage.onload = () => {
        svgCtx.drawImage(svgImage, 0, 0, svgCanvas.width, svgCanvas.height);
        image.current = svgCanvas;
        preparing.current = false;
        dirty.current = true;
      };
    }, [track])

    useEffect(() => {
      prepareSvg();
    }, [track])

    const checkCurrentTrackProgress = useCallback(() => {
      let p = -1;
      if(file && track && file.id === track.file.id && runtime.duration){
        isActive.current = true;
        p = runtime.currentTime / runtime.duration;
      } else {
        isActive.current = false;
      }
      if(p !== progress.current){
        progress.current = p;
        dirty.current = true;
      }
    }, [track,file])
  
    useRaf(() => {
      if(!image.current || !canvas.current) return;
  
      if(!preparing.current && image.current.width !== canvas.current.offsetWidth){
        prepareSvg();
      }

      checkCurrentTrackProgress();

      if(dirty.current){
        renderCanvas();
      }
    }, true)


    return <canvas ref={canvas} style={style} />
  }