

import useNotificationStore from "../store/notification/index"
import useSessionStore from "../store/session/index"

export function useUpdateNotification(){
    
	const update = useNotificationStore(state => state.update);
    const jwt = useSessionStore(state => state.jwt);

    return (data) => update(jwt, data)
}

export function useDeleteNotification(){
    
	const deleteNotification = useNotificationStore(state => state.deleteNotification);
    const jwt = useSessionStore(state => state.jwt);

    return (data) => deleteNotification(jwt, data)
}
export function useDeleteTrackNotifications(){
    
	const deleteTrackNotifications = useNotificationStore(state => state.deleteTrackNotifications);
    const jwt = useSessionStore(state => state.jwt);

    return (data) => deleteTrackNotifications(jwt, data)
}