import React, { useEffect, useCallback, useRef } from "react";
import useRaf from "@rooks/use-raf";
import usePlayerStore from "./store/player/usePlayer";
import {PageTheme} from "./Theme"

const font = (12 * window.devicePixelRatio) + 'px Arial';
const letterWidth = 5.6 * window.devicePixelRatio;
const textPadding = 4 * window.devicePixelRatio;

export default function CanvasScrubber({track,style,marginTop,marginBottom}){
    const canvas = useRef();
    const dirty = useRef();
    const image = useRef();
    const value = useRef();
    const isActive = useRef();
    const isHovered = useRef();
    const preparing = useRef(false);
    const {runtime,setCurrentTime,duration,attach,detach,setCommentStart,setCommentEnd,resetDirty,setCommentSynced,commentSynced,commentActive,setCursorPosition} = usePlayerStore();
    
    const isScrubbing = useRef(false);
    const position = useRef(-1);
  
    const renderCanvas = useCallback(() => {
      dirty.current = false;
      resetDirty();
  
      canvas.current.width = canvas.current.offsetWidth * window.devicePixelRatio;
      canvas.current.height = canvas.current.offsetHeight * window.devicePixelRatio;
      const ctx = canvas.current.getContext('2d');
      ctx.font = font
  
      const sWidth = image.current.width * value.current;
      const dWidth = canvas.current.width * value.current;
      const dHeight = canvas.current.height - marginTop - marginBottom;
      
      ctx.globalAlpha = 0.9;
      ctx.drawImage(image.current, 0, 0, sWidth, image.current.height, 0, marginTop, dWidth, dHeight);
  
      ctx.globalAlpha = 0.3;
      ctx.drawImage(image.current, sWidth, 0, image.current.width, image.current.height, dWidth, marginTop, canvas.current.width, dHeight);
      
      if(commentActive){
        ctx.globalAlpha = 0.4;
        ctx.fillStyle = PageTheme.colors.create;
        ctx.rect(runtime.commentStart * canvas.current.width, 0, runtime.commentEnd * canvas.current.width - runtime.commentStart * canvas.current.width + 1, canvas.current.height);
        ctx.fill();
  
        if((isHovered.current || isActive.current) && position.current >= 0){
            
          const pos = Math.abs(position.current - runtime.commentStart) < Math.abs(position.current - runtime.commentEnd) ? runtime.commentStart : runtime.commentEnd
          const x = pos * canvas.current.width;
          ctx.globalAlpha = 1;
          ctx.strokeStyle = PageTheme.colors.create;
          ctx.lineWidth = 5
          ctx.beginPath();
          ctx.moveTo(x, 0);
          ctx.lineTo(x, canvas.current.height);
          ctx.stroke();
        }

        const xStart = runtime.commentStart * canvas.current.width;
        const xEnd = runtime.commentEnd * canvas.current.width;

        const progressStart = `${(runtime.commentStart * runtime.duration).toFixed(1)} s`;
        const xOffsetStart = xStart >= progressStart.length*letterWidth + textPadding ? -progressStart.length * letterWidth - textPadding : textPadding;
        ctx.textBaseline="top"
        ctx.globalAlpha = 1;
        ctx.fillStyle="#fff"
        ctx.fillText(progressStart, xStart + xOffsetStart, textPadding)

        const progressEnd = `${(runtime.commentEnd * runtime.duration).toFixed(1)} s`;
        const xOffsetEnd = xEnd >= canvas.current.width - progressEnd.length*letterWidth - textPadding ? -progressEnd.length * letterWidth - textPadding : textPadding;
        ctx.textBaseline="top"
        ctx.globalAlpha = 1;
        ctx.fillStyle="#fff"
        ctx.fillText(progressEnd, xEnd + xOffsetEnd, textPadding)
      } else {      
        let x;
        if(position.current >= 0){
          x = position.current * canvas.current.width;
          
          const progress = `${(position.current * runtime.duration).toFixed(1)} s`;
          const xOffset = x >= canvas.current.width - progress.length * letterWidth - textPadding ? -progress.length * letterWidth - textPadding : textPadding;
          ctx.textBaseline="top"
          ctx.globalAlpha = 1;
          ctx.fillStyle="#fff"
          ctx.fillText(progress, x + xOffset, textPadding)
        } else if(runtime.playing) {
          x = value.current * canvas.current.width;
        }
        ctx.globalAlpha = 1;
        ctx.strokeStyle = PageTheme.colors.audio;
        ctx.lineWidth = 1
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, canvas.current.height);
        ctx.stroke();
      }
    }, [commentActive])
  
    const updatePlayerProgressFromCursor = useCallback((event) => {
      setCurrentTime(position.current * runtime.duration)
    })
  
    function handlePlayerProgress(v){
      value.current = v;
      if(commentSynced){
        setCommentEnd(v);
      }
    }
  
    const updateCursor = useCallback((event) => {
      if(!canvas.current) return;
      event.preventDefault();
      const rect = canvas.current.getBoundingClientRect();
      const x = (event.touches ? event.touches[0].clientX : event.clientX) - rect.left;
      setCursorPosition(x / (rect.width - 1))
      dirty.current = true;
      if(isScrubbing.current && position.current >= 0){
        if(runtime.commentActive){
          if(Math.abs(position.current - runtime.commentEnd) < Math.abs(position.current - runtime.commentStart)){   
            setCommentEnd(position.current)
            if(commentSynced) setCommentSynced(false);
          } else {
            setCommentStart(position.current)
          }
        } else {
          updatePlayerProgressFromCursor()
        }
      }
    }, [commentSynced])
  
    const startScrubbing = useCallback((event) => {
      isScrubbing.current = true;
  
      const rect = canvas.current.getBoundingClientRect();
      const x = (event.touches ? event.touches[0].clientX : event.clientX) - rect.left;
      const v = x / (rect.width - 1);
      
      if(Math.abs(v - runtime.commentEnd) < Math.abs(v - runtime.commentStart)){ 
        setCommentSynced(false);
      }
  
      updateCursor(event)
    })
  
    const endScrubbing = useCallback((event) => {
      isScrubbing.current = false;
    }, [])
  
    const unsetCursor = useCallback(() => {
        setCursorPosition(-1)
        dirty.current = true;
    }, [])
  
    useEffect(() => {
      dirty.current = true;
    }, [commentActive])

    const prepareSvg = useCallback(() => {
      if(preparing.current) return false;

      preparing.current = true;

      const svgCanvas = document.createElement('canvas')
      const svgCtx = svgCanvas.getContext("2d");
      svgCanvas.width = canvas.current.offsetWidth;
      svgCanvas.height = canvas.current.offsetHeight;
  
      const svgImage = new Image();
      svgImage.src = track.file.formats.svgWaveform.url;
      svgImage.onload = () => {
        svgCtx.drawImage(svgImage, 0, 0, svgCanvas.width, svgCanvas.height);
        image.current = svgCanvas;
        preparing.current = false;
        dirty.current = true;
      };
    }, [track])

    useEffect(() => {
      attach();
      prepareSvg();
      return () => detach();
    }, [track])
  
    useRaf(() => {
      if(!image.current || !canvas.current) return;
  
      if(!preparing.current && image.current.width !== canvas.current.offsetWidth){
        prepareSvg();
      }

      const newValue = runtime.currentTime / duration;
      if(newValue !== value.current){
        handlePlayerProgress(newValue);
        dirty.current = true;
      }
      if(runtime.cursorPosition !== position.current){
        position.current = runtime.cursorPosition;
        dirty.current = true;
      }
      if(dirty.current || runtime.dirty){
        renderCanvas();
      }
    }, true)

    const handleMouseOver = useCallback((e) => {
        isHovered.current = true;
        updateCursor(e)
    }, []);
    const handleMouseMove = useCallback((e) => {
        if(isActive.current || isHovered.current){
            updateCursor(e)
        }
    }, []);
    const handleMouseOut = useCallback((e) => {
        isHovered.current = false;
        if(!isScrubbing.current){
            unsetCursor()
        }
    }, []);
    const handleMouseDown = useCallback(e => {
        isActive.current = true;
        startScrubbing(e);
        updateCursor(e)
    }, []);
    const handleMouseUp = useCallback( (e) => {
        if(!isHovered.current){
            unsetCursor()
        }
        if(isActive.current){
            endScrubbing();
            isActive.current = false;
        }
    }, []);
    const handleTouchStart = useCallback( (e) => {
        startScrubbing(e);
    }, []);
    const handleTouchMove = useCallback( (e) => {
        updateCursor(e);
    }, []);
    const handleTouchEnd = useCallback( (e) => {
        endScrubbing(e);
        unsetCursor()
    }, []);
    const handleClick = useCallback( (e) => {
      canvas.current.focus();
    }, []);
  
    useEffect(() => {
        canvas.current.addEventListener('click', handleClick)
        canvas.current.addEventListener('touchstart', handleTouchStart)
        canvas.current.addEventListener('touchmove', handleTouchMove)
        canvas.current.addEventListener('touchend', handleTouchEnd)

        canvas.current.addEventListener('mouseover', handleMouseOver)
        canvas.current.addEventListener('mouseout', handleMouseOut)
        canvas.current.addEventListener('mousedown', handleMouseDown)
        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)

        return () => {
            document.removeEventListener('mousemove', handleMouseMove)
            document.removeEventListener('mouseup', handleMouseUp)
            
            if(!canvas.current) return;

            canvas.current.removeEventListener('click', handleClick)

            canvas.current.removeEventListener('touchstart', handleTouchStart)
            canvas.current.removeEventListener('touchmove', handleTouchMove)
            canvas.current.removeEventListener('touchend', handleTouchEnd)

            canvas.current.removeEventListener('mouseover', handleMouseOver)
            canvas.current.removeEventListener('mouseout', handleMouseOut)
            canvas.current.removeEventListener('mousedown', handleMouseDown)
        }
    }, [])

    return <canvas ref={canvas} tabIndex="-1" style={{...style,cursor:commentActive?'ew-resize':'col-resize',outline:0}} />
  }