import styled from 'styled-components';

const ItemMenu = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: none;

  & > * {
    pointer-events: all;
  }

  & > *:not(:first-child) {
    margin-left: 4px;
  }
`
export default ItemMenu;