import { apiBaseUrl } from "../../config/environment";

export async function update(jwt){
    const url = `${apiBaseUrl}notifications`;
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${jwt}`
        },
    });

    if(!response.ok){
        const { error } = await response.json();
        return { ok: false, error };
    }

    const notifications = await response.json();
    return { ok: true, notifications };
}

export async function deleteNotification(jwt, id){
    const url = `${apiBaseUrl}notifications/${id}`;
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${jwt}`
        },
    });

    if(!response.ok){
        const { error } = await response.json();
        return { ok: false, error };
    }

    const notifications = await response.json();
    return { ok: true, notifications };
}

export async function deleteTrackNotifications(jwt, id){
    const url = `${apiBaseUrl}notifications/track/${id}`;
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${jwt}`
        },
    });

    if(!response.ok){
        const { error } = await response.json();
        return { ok: false, error };
    }

    const notifications = await response.json();
    return { ok: true, notifications };
}