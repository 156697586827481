

import useTrackStore from "../store/track/index"
import useSessionStore from "../store/session/index"

export function useTrackUpload(){
    
	const upload = useTrackStore(state => state.upload);
    const jwt = useSessionStore(state => state.jwt);

    return (data) => upload(jwt, data)
}

export function useTrackImageUpload(){
    
	const uploadImages = useTrackStore(state => state.uploadImages);
    const jwt = useSessionStore(state => state.jwt);

    return (track, files) => uploadImages(jwt, track.uid, files)
}

export function useFetchMyTracks(){
    
	const fetchMine = useTrackStore(state => state.fetchMine);
    const jwt = useSessionStore(state => state.jwt);

    return () => fetchMine(jwt)
}

export function useTrackComment(){
    
	const comment = useTrackStore(state => state.comment);
    const {user,jwt} = useSessionStore();
    const authenticated = !!user;

    return (data) => comment(jwt, authenticated, data)
}

export function useDeleteTrack(){
    
	const deleteTrack = useTrackStore(state => state.deleteTrack);
    const jwt = useSessionStore(state => state.jwt);

    return (track) => deleteTrack(jwt, track)
}

export function useDeleteImage(){
    
	const deleteImage = useTrackStore(state => state.deleteImage);
    const jwt = useSessionStore(state => state.jwt);

    return (trackUid, image) => deleteImage(jwt, trackUid, image)
}

export function useUpdateImages(track){
    
	const updateImages = useTrackStore(state => state.updateImages);
    const jwt = useSessionStore(state => state.jwt);

    return async (images) => await updateImages(jwt, track, images)
}

export function useDeleteComment(){
    
	const deleteComment = useTrackStore(state => state.deleteComment);
    const jwt = useSessionStore(state => state.jwt);

    return (trackUid, comment) => deleteComment(jwt, trackUid, comment)
}

export function useUpdatePlaylists(){
    
	const updatePlaylists = useTrackStore(state => state.updatePlaylists);
    const jwt = useSessionStore(state => state.jwt);

    return (playlists) => updatePlaylists(jwt, playlists)
}