import React, { useEffect } from "react";
import { setDocumentTitle } from "./App";


export default function Home() {
  useEffect(() => setDocumentTitle(''), [])

  return (
    <h1>What is this Page all about?</h1>
  );
}