import styled,{css} from 'styled-components'
import {PageTheme} from './Theme'

const Indicator = styled.div`
    padding: 2px 4px;
    font-size: 14px;
    display: inline-flex;
    color: #fff;
    font-weight: bold;
    letter-spacing: 2px;
    background: ${PageTheme.colors.create};
    border-radius: 4px;
    min-width: 24px;
    min-height: 24px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    svg {
        height: 16px;
        display: inline-block;
        fill: #fff;
    }

    ${props => props.onClick ? css`
        cursor: pointer;
        &:hover {
            svg {
                fill: ${PageTheme.colors.create};
            }
            background: #fff;
        }
    ` : ''}
`

export default Indicator;